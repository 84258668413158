
import { Component, Prop, Mixins } from "vue-property-decorator";
import { MindsetReportMixin, MindsetMixin, RoutingMixin, BAIconsMixin } from "@/mixins"

@Component
export default class MindsetInformation extends Mixins(MindsetReportMixin, MindsetMixin, RoutingMixin, BAIconsMixin) {
    // inherited from MindsetMixin
    // @Prop({ default: undefined }) userId;

    @Prop({type: Boolean}) showInfo;
    @Prop({type: Boolean}) showDetails;

    onClickSeeInfo() {
        this.showDetails = true;
        this.showInfo = true;
    }
    onClickHideInfo() {
        this.showDetails = false;
        this.showInfo = false;
    }
}

