
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ScoutingScoreColorMixin } from '@/mixins';


@Component
export default class ScoutingReportScoreChip extends Mixins(ScoutingScoreColorMixin){
	// ScoutingReportScoreChip class properties
	@Prop({ required: true }) public scores: number[];
	@Prop({ default: "small" }) fontSize: string;
	@Prop({ default: 75 }) size: number;
	@Prop({ default: -1 }) width: number;
	@Prop({ default: 'red' }) color: string;

	get FontClass(): String {
		if( this.fontSize.toUpperCase().startsWith('S') ) return 'scorechip-S';
		if( this.fontSize.toUpperCase().startsWith('M') ) return 'scorechip-M';
		if( this.fontSize.toUpperCase().startsWith('L') ) return 'scorechip-L';
		return 'scorechip';
	}

	get Width(): Number {
		if( this.width < 0 ) return this.size * 0.2;
		return this.width;
	}
	// Compute the score based on the average of the scores passed in
	get score(): Number {
		const sum = this.scores.reduce((a,b) => a+b, 0);
		return (sum / this.scores.length) || 0;
	}

	get percentage(): Number {
		const maxScore = 4.25;
		return this.score.valueOf() / maxScore * 100;
	}
}
