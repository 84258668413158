
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAPartnerMixin, PartnersRoutingMixin, VuetifyMixin } from '@/mixins';
import { userStore } from '@/store';

@Component
export default class Partner extends Mixins(VuetifyMixin, BAPartnerMixin, PartnersRoutingMixin) {
    @Prop({required: true}) partnerName: string;

    mounted() {
        if( this.$route.path.includes('public') ) {
            if( userStore.IsReady ) {
                this.gotoRoute(this.RouteToPartner(this.partnerName));
                return;
            }
        }
    }
}

