
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { notificationStore } from '@/store';
import { scoutingReportApi } from '@/api/ScoutingReportApi';
import { PageState } from '@/models/PageState';

export type SaveReportFn = (report: ScoutingReportModel, sendReport: boolean) => Promise<ScoutingReportModel>

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	},
})
export default class ScoutingRecordDetailProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop() reportId: string;
	/** Provide to load a report linked to an athlete Id. Requires Read access to athlete */
	@Prop({ default: null }) athleteId: string | null;
	
	async created(): Promise<void> {
		await this.initReport();
	}
	async loadScoutingData(): Promise<void> {
		if(this.reportId === null) return;
		if(this.athleteId === null){
			this.scoutingReport = await scoutingReportApi.findById(this.reportId);
		}else{
			this.scoutingReport = await scoutingReportApi.findByAthleteId(this.athleteId, this.reportId);
		}
		if(this.scoutingReport === null){
			throw null; // Render 404 Not Found Error
		}
	}
	state: PageState = new PageState("Initial");

	scoutingReport: ScoutingReportModel | null = null;

	async initReport():Promise<void> {
		this.state = new PageState("Loading");
		try{
			await this.loadScoutingData();
			this.state = new PageState("Ready");
		}catch(error){
			if(error !== null){
				notificationStore.pushErrorNotificationMessage("Failed to load Scouting Report. Please try again later.");
			}
			this.state = PageState.getPageState(error);
		}
	}

	get SlotProps(): Record<string,any> {
		return {
			state: this.state,
			ScoutingReport: this.scoutingReport,
			saveReport: this.saveReport.bind(this),
		}
	}

	get FourMomentsValues(): number[]{
		if (!this.scoutingReport.FourMomentAvailable) return [];
		return [
			this.scoutingReport.evaluationMatrix.attackingOrganization,
			this.scoutingReport.evaluationMatrix.attackingTransition,
			this.scoutingReport.evaluationMatrix.defendingOrganization,
			this.scoutingReport.evaluationMatrix.defendingTransition,
		]
	}
	get SixMomentValues(): number[]{
		if (!this.scoutingReport.SixMomentAvailable) return [];
		return  [
			this.scoutingReport.evaluationMatrix.attackingOrganization,
			this.scoutingReport.evaluationMatrix.attackingTransition,
			this.scoutingReport.evaluationMatrix.attackingSetPlays,
			this.scoutingReport.evaluationMatrix.defendingOrganization,
			this.scoutingReport.evaluationMatrix.defendingTransition,
			this.scoutingReport.evaluationMatrix.defendingSetPlays,
		]
	}
	get CRISPValues(): number[]{
		if (!this.scoutingReport.CRISPAvailable) return [];
		return [
			this.scoutingReport.evaluationMatrix.competitive,
			this.scoutingReport.evaluationMatrix.resilience,
			this.scoutingReport.evaluationMatrix.intelligence,
			this.scoutingReport.evaluationMatrix.speed,
			this.scoutingReport.evaluationMatrix.presence
		]
	}

	async saveReport(report: ScoutingReportModel, sendReport: boolean = false): Promise<ScoutingReportModel> {
		let saved: ScoutingReportModel;
		if(!report.id){
			saved = await scoutingReportApi.insertWithOwnership(report, { params: { supressEvents: false, sendReport }});
		}else{
			saved = await scoutingReportApi.save(report, { params: { supressEvents: false, sendReport }});
		}
		/** Update our loaded report if they're the same */
		if(this.scoutingReport && saved.id === this.scoutingReport.id){
			this.scoutingReport = new ScoutingReportModel().load({
				...this.scoutingReport,
				playerDetails:{
					...saved.playerDetails,
				},
				contactInfo:{
					...saved.contactInfo,
				},
				lastModified: saved.lastModified,
				anonymousCoach: saved.anonymousCoach
			})
		}
		return saved;
	}

}
