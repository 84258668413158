
import { Component, Vue, Prop } from 'vue-property-decorator';
import ScoutingReportScoreChip from "@/components/scoutingReport/ScoutingReportScoreChip.vue";
import RadarChart from "@/components/charts/RadarChart.vue";
import RadarChartJS from "@/components/charts/RadarChartJS.vue";

@Component({
	components:{ ScoutingReportScoreChip, 
				 RadarChart,
				 RadarChartJS
			   },
})
export default class ScoutingReportSpiderChart extends Vue{
	@Prop({ type: Boolean }) available;
	@Prop({ required: true }) public heading: string;
	@Prop({ required: true }) public labels: string[];
	@Prop({ required: true }) public scores: number[];
	@Prop({ type: Boolean }) hideScore;
}
