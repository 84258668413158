import { render, staticRenderFns } from "./RyzerAthleteTypeCard.vue?vue&type=template&id=ff44367e&scoped=true&"
import script from "./RyzerAthleteTypeCard.vue?vue&type=script&lang=ts&"
export * from "./RyzerAthleteTypeCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff44367e",
  null
  
)

export default component.exports