

import {
	Chart as ChartJS,
	Legend,
	LineElement,
	RadialLinearScale,
	PointElement,
	Title,
	Tooltip,
	ChartData,
	ChartOptions,
registerables,
} from 'chart.js';

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, ScoutingScoreColorMixin } from '@/mixins';
import { RadarChartSeries } from "@/../types/interfaces/RadarChartSeries";
import { Radar } from 'vue-chartjs/legacy';

ChartJS.register(
	Title,
	Tooltip,
	Legend,
	PointElement,
	RadialLinearScale,
	LineElement
)

@Component({
	components:{
		Radar,
	}
})
export default class RadarChartJS extends Mixins(VuetifyMixin, ScoutingScoreColorMixin){

	@Prop({default: 0, type: Number }) private scaleMin: number;
	@Prop({default: 6, type: Number }) private scaleMax: number;
	@Prop({default: 9, type: Number }) private labelSize: number;
	@Prop({required: true}) public labels: string[];
	@Prop({required: true}) public data: RadarChartSeries[];

	get RadarChartData(): ChartData{
		return {
			// display labels in uppercase
			labels: this.labels.map(l => {return l.toUpperCase()}),

			// chart data
			datasets: this.data.map(data => {
				return {
					label: data.name,
					data: data.values,
					borderColor: this.getColor('baColorProfessional'),
					backgroundColor: this.getColor('baColorHighPerformance'),
				}
			})
		}
	}

	get RadarChartDataOptions(): ChartOptions {
		this.ScoutingScoreColor
		return {
			responsive: true,
			maintainAspectRatio: true,
			elements: {
				point: {
					radius: 8,
					hitRadius: 24,
					hoverRadius: 32,
					hoverBorderWidth: 8,
				},
			},
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					backgroundColor: 'white',
					titleColor: this.getColor('baColorProfessional'),
					displayColors: false,
					titleAlign: 'center',
					titleFont: {
						size: 16,
						weight: 'bold'
					},
					bodyFont: {
						size: 32					
					},
					bodyAlign: 'center',
					callbacks: {
						title: function(context) {
							return context[0].label.toUpperCase();
						},
						label: function(context) {
							return context.formattedValue;
						},
						labelTextColor: function(context) {
							if( Number(context.formattedValue) > 4.25 ) return '#6A1B9A';     	// purple darken-3
							else if( Number(context.formattedValue) > 3.25 ) return '#1B5E20';  // green darken-4
							else if( Number(context.formattedValue) > 2.75 ) return '#558B2F';  // light-green darken-3
							else if( Number(context.formattedValue) > 1.75 ) return '#9E9D24';  // lime darken-3
							else if( Number(context.formattedValue) > 0.75 ) return '#BF360C';  // deep-orange darken-4
							else if( Number(context.formattedValue) > 0 ) return '#B71C1C';     // red darken-4
							return 'blue';
						}
					}
				}
			},
			scales: {
				r: {
					pointLabels: {
						color: this.getColor('baColorProfessional'),
						font: {
							size: this.labelSize,
							weight: 'bold',
						}
					},
					grid: {
						display: true,
						circular: true
					},
					ticks: {
						display: false,
						stepSize: 1
					},
					min: this.scaleMin,
					max: this.scaleMax,
				}
			}
		}
	}
}

